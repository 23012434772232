import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="service"
export default class extends Controller {
  static targets = ["offerBloc", "shortDescriptionBloc"];

  connect() {
    let widthLimit = 767;
    let bloc = this.offerBlocTarget;
    let defaultBloc = bloc.cloneNode(true);
    let shortDescriptionBloc = this.shortDescriptionBlocTarget;
    if (window.innerWidth < widthLimit) {
      shortDescriptionBloc.append(defaultBloc);
      bloc.hidden = true;
    } else {
      bloc.hidden = false;
    }

    window.addEventListener("resize", function () {
      if (window.innerWidth < widthLimit) {
        shortDescriptionBloc.append(defaultBloc);
        bloc.hidden = true;
      } else {
        bloc.hidden = false;
        shortDescriptionBloc.removeChild(defaultBloc);
      }
    });
  }
}
