import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="article"
export default class extends Controller {
  static values = {
    text: String
  }

  connect() {
    console.log("Hello, cta article controller");
    // console.log(this.textValue);
    var ctaText = "Un devis adapté à vos besoins ?";
    if(this.textValue) {
      console.log("Texte dynamique : " + this.textValue);
      ctaText = this.textValue;
    }
    const articleContent = this.element; // `this.element` fait référence à l'élément qui a ce contrôleur
    if (articleContent) {
      const h2Elements = articleContent.querySelectorAll('h2');
      for (let i = 1; i < h2Elements.length; i++) { // Commencer à partir de 1 pour ignorer le premier <h2>
        const ctaBlock = document.createElement('div');
        ctaBlock.className = 'customer_cta_black';
        ctaBlock.innerHTML = `
          <h3>${ctaText}</h3>
          <a class="btn btn-flat" href="/prendre-rendez-vous">Prendre un RDV</a>
        `;
        h2Elements[i].parentNode.insertBefore(ctaBlock, h2Elements[i]);
      }
    }
  }
}
